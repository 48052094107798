var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pt-2",style:({'max-width': _vm.screenWidth}),attrs:{"id":"userTimeSheet-container"}},[_c('table-component',{attrs:{"headers":_vm.tableHeaders,"height":'calc(100vh - 285px)',"items":_vm.timesheetData,"item-key":"id"},scopedSlots:_vm._u([{key:"month",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"align-center overflow-handle"},[_c('span',[_vm._v(_vm._s(_vm.getMonthName(item))+" ")])])]}},{key:"year",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"align-center overflow-handle"},[_c('span',[_vm._v(_vm._s(_vm.getYearName(item))+" ")])])]}},{key:"workSessionsCount",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"align-center overflow-handle"},[_c('span',[_vm._v(_vm._s(_vm.getWorkSessionsCount(item))+" ")])])]}},{key:"totalWorkTime",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"align-center overflow-handle"},[_c('span',[_vm._v(_vm._s(item.totalWorkTimeHumanized[_vm.locale])+" ")])])]}},{key:"lastConfirmation",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"align-center overflow-handle",staticStyle:{"margin-left":"20px"}},[(item.employeeSignature)?_c('span',[_vm._v(" "+_vm._s(_vm._f("toDate")(item.employeeSignature.createdAt))+" ")]):_c('span',[_vm._v("-")])])]}},{key:"options",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"mr-3 primary--text align-center pointer",on:{"click":function($event){return _vm.downloadTimesheet(item)}}},[_c('v-icon',{attrs:{"size":"25"}},[_vm._v("mdi-download-outline")])],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }